import { createCameraVideoTrack } from '@videosdk.live/react-sdk';

const useMediaStream = () => {
    
  const getVideoTrack = async ({ optimizationMode, cameraId, encoderConfig, multiStream, facingMode }) => {
    try {
      const track = await createCameraVideoTrack({
        optimizationMode: optimizationMode,
        cameraId: cameraId,
        encoderConfig: encoderConfig,
        multiStream: multiStream,
        facingMode: facingMode
      });

    //   console.log("Track created", track);
      return track;
    } catch (e) {
      return null;
    }
  };

  return { getVideoTrack };
};

export default useMediaStream;
