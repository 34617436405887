import styled from 'styled-components';

export const Indicator = styled.div`
  width: ${({ small }) => (small ? 24 : 36)}px;
  height: ${({ small }) => (small ? 24 : 36)}px;
  background-color: ${({ backgroundColor }) => backgroundColor ?? '#494949'};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IndicatorGroup = styled.div`
  position: absolute;
  top: ${({ top }) => top ?? 0}px;
  left: ${({ left }) => left ?? 0}px;
  display: flex;
  column-gap: 4px;
  z-index: 10;
`;
