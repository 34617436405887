import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from 'components/Button';

const WaitingRoomHeader = ({ showHeader, showInfo, setShowInfo }) => {
  const { t } = useTranslation();

  const handleClickInfo = () => {
    setShowInfo(prevCheck => !prevCheck);
  };

  const handleClickHide = () => {
    setShowInfo(false);
  };

  return (
    <div className={classNames('chatbox-header-group', { 'chatbox-header-hide': !showHeader })}>
      <div className="chatbox-header chatbox-header--sidebar">
        {!showInfo && (
          <>
            <div className="chatbox-header-menu__mobile">
              <div className="d-flex justify-content-end w-100">
                <div className="chatbox-header-menu__mobile-button">
                  <Button onClick={handleClickInfo} variant="info" active={showInfo} />
                </div>
              </div>
            </div>
          </>
        )}
        {showInfo && (
          <div className="chatbox-header-menu__mobile">
            <div className="d-flex justify-content-end w-100">
              <button className="button clear-blue" onClick={handleClickHide}>
                <span className="text-blue" style={{ fontSize: 14, fontWeight: 600 }}>
                  {t('Hide')}
                </span>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

WaitingRoomHeader.propTypes = {
  showHeader: PropTypes.bool,
  showInfo: PropTypes.bool
};

WaitingRoomHeader.defaultProps = {
  showHeader: false,
  showInfo: false
};

export default WaitingRoomHeader;
