import { createContext, useContext, useMemo, useState, useCallback } from 'react';
import { CSAT_STEPS, CSAT_QUESTION_TYPE } from 'libs/constant';
import AppointmentAPI from 'libs/api/appointment';
import useChat from 'libs/hooks/useChat';

const CSATCtx = createContext({
  questions: [],
  appointment: {}
});

const CSATProvider = ({ children }) => {
  const {
    action,
    value: { appointment, isDoctor, doctor, patient }
  } = useChat();
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(CSAT_STEPS.IDLE);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [isLastQuestion, setIsLastQuestion] = useState(false);

  const getQuestions = useCallback(async () => {
    if (!isLoading && appointment?.appointmentNumber && appointment?.fgCSAT) {
      setIsLoading(true);

      // need to re-fetch the appointment detail to get actual fee charged by practitioner.
      if (appointment?.payment?.total > 0) {
        await action.getAppointmentDetail({
          appointmentNumber: appointment?.appointmentNumber
        });
      }

      const [, res] = await AppointmentAPI.getCSATQuestions(appointment?.appointmentNumber);
      const list = res?.data?.data || [];

      // default set step to complete screen
      step !== CSAT_STEPS.COMPLETE_SCREEN && setStep(CSAT_STEPS.COMPLETE_SCREEN);

      if (list?.length > 0) {
        setQuestions([...list]);
        setIsLastQuestion(list.length === 1);
      } else if (questions?.length > 0) {
        // reset question ...
        setQuestions([]);
      }

      setIsLoading(false);
    }
  }, [
    isLoading,
    appointment?.appointmentNumber,
    appointment?.payment?.total,
    appointment?.fgCSAT,
    questions?.length,
    step,
    action
  ]);

  const onRangeChange = useCallback(
    (question, option, idx) => {
      setAnswers({
        ...answers,
        [question?.id]: {
          answerIdx: idx,
          type: question?.type,
          message: option?.message,
          answer: option?.answer
        }
      });

      if (step === CSAT_STEPS.COMPLETE_SCREEN) {
        setStep(CSAT_STEPS.QUESTION_SCREEN);
      }
    },
    [answers, step]
  );

  const onMultipleChoicesChange = useCallback(
    (question, option) => {
      const answerItem = answers?.[question?.id];
      if (answerItem) {
        const idx = answerItem?.answer?.indexOf(option?.answer);
        if (idx >= 0) {
          answers[question?.id]?.answer?.splice(idx, 1);
        } else {
          answers[question?.id]?.answer?.push(option?.answer);
        }
      } else {
        answers[question?.id] = {
          question_id: question?.id,
          answer: [option?.answer],
          type: question?.type
        };
      }
      setAnswers({ ...answers });
    },
    [answers]
  );

  const onQuestionChange = useCallback(
    (question, option, idx) => {
      const { type } = question;
      if (type === CSAT_QUESTION_TYPE.RANGE) {
        onRangeChange(question, option, idx);
      } else if (type === CSAT_QUESTION_TYPE.MULTIPLE_CHOICES) {
        onMultipleChoicesChange(question, option, idx);
      }
    },
    [onRangeChange, onMultipleChoicesChange]
  );

  const onNextQuestion = useCallback(() => {
    const nextIndex = questionIndex + 1;
    const lastIndex = questions?.length - 1;
    if (nextIndex <= lastIndex) {
      setQuestionIndex(questionIndex + 1);
    }
    setIsLastQuestion(nextIndex === lastIndex);
  }, [questions?.length, questionIndex]);

  const onSubmitAnswers = useCallback(async () => {
    const payloads = [];
    Object?.keys(answers)?.forEach(questionId => {
      if (answers?.[questionId]?.answer) {
        payloads.push({
          question_id: +questionId,
          answer: answers[questionId].answer
        });
      }
    });

    if (appointment?.appointmentNumber && payloads?.length > 0) {
      setIsLoading(true);

      const remoteUser = isDoctor ? doctor : patient;
      const userName = [remoteUser?.first_name, remoteUser?.last_name].join(' ');

      const [, res] = await AppointmentAPI.submitCSATAnswers({
        appointment_number: appointment?.appointmentNumber,
        answers: payloads,
        username: userName
      });

      if (res) {
        setStep(CSAT_STEPS.THANK_YOU_SCREEN);
      }

      setIsLoading(false);
    }
  }, [answers, appointment?.appointmentNumber, isDoctor, doctor, patient]);

  const ctxValues = useMemo(
    () => ({
      isLoading,
      questions,
      appointment,
      step,
      getQuestions,
      onNextQuestion,
      onSubmitAnswers,
      onQuestionChange,
      isLastQuestion,
      answers,
      questionIndex
    }),
    [
      appointment,
      questions,
      step,
      getQuestions,
      onNextQuestion,
      onSubmitAnswers,
      isLoading,
      onQuestionChange,
      isLastQuestion,
      answers,
      questionIndex
    ]
  );

  return <CSATCtx.Provider value={ctxValues}>{children}</CSATCtx.Provider>;
};

export const useCSATContext = () => useContext(CSATCtx);

export default CSATProvider;
