import { usePubSub } from '@videosdk.live/react-sdk';
import { useEffect, useRef } from 'react';

import useChat from 'libs/hooks/useChat';
import { CONSULTATION_STATUS, EVENT_PUBSUB } from 'libs/constant';
import MeetingContainer from './meeting-container';
import { getFullDatetime } from 'libs/date';
import getRecordingConfig from 'libs/recording-config';
import { MESSAGES_ERROR, useVideoSDK } from 'libs/hooks/useVideoSDK';
import useToast from 'libs/hooks/useToast';
import { isOutSystemApp } from 'libs/okaBrowserCheck';
import useTracking from 'libs/hooks/useTracking';
import { useGlobalConfigStore } from 'zustandStore';

const recordingConfig = getRecordingConfig();
const isStaging = process.env.NODE_ENV === 'staging';

const Meeting = ({
  setShowVideo,
  setShowChat,
  setShowInfo,
  setShowFullChat,
  showVideo,
  showChat,
  showInfo,
  showFullChat,
  participantType,
  country,
  lang,
  isPatient,
  setIsUnread,
  wlVersion,
  isXToken,
  t
}) => {
  const _mounted = useRef();
  const {
    value: { telemedicine, isDoctor, doctor, patient, appointment },
    action
  } = useChat();
  const tracking = useTracking({
    from: 'Meeting'
  });

  const { show } = useToast();
  const { publish } = usePubSub(EVENT_PUBSUB.PARTICIPANTS_STATUS);
  const { publish: publishChat } = usePubSub(EVENT_PUBSUB.CHAT);
  const shallRecord = telemedicine?.shallrecorded;
  const handleSendEvent = (isDoctorFlag, status) => {
    const messageToSent = {
      message: {
        status,
        isDoctor: isDoctorFlag
      },
      type: 'message'
    };
    publish(JSON.stringify(messageToSent), { persist: true });
  };

  const { startRecording, meetingId, isJoined, isRecording } = useVideoSDK({
    onParticipantLeft: participant => {
      const isEventFromDoctor = !!participant?.id?.includes('doctor');

      handleSendEvent(isEventFromDoctor, CONSULTATION_STATUS.YET_TO_JOIN);
    },
    onParticipantJoined: participant => {
      const isEventFromDoctor = participant.id.includes('doctor');

      handleSendEvent(isEventFromDoctor, CONSULTATION_STATUS.IN_CONSULTATION);
    },
    onError: async data => {
      const { code, message } = data;

      const messageToast = MESSAGES_ERROR?.[code] ?? `${code} :  ${message}`;
      const delay = Math.floor( messageToast?.length / 20) * 1000;

      show({
        type: 'danger',
        message: messageToast,
        delay
      });

      await tracking.trackEvent(
        'error_meeting',
        isDoctor ? 'doctor' : 'patient',
        {
          timestamps: new Date(Date.now()).toUTCString(),
          state: {
            microphone_current_state: useGlobalConfigStore.getState().micOn,
            camera_current_state: useGlobalConfigStore.getState().webcamOn,
          },
          error: {
            code: code,
            message: messageToast
          },
          environment: isStaging ? 'staging': 'production',
          user_agent: navigator.userAgent.toLowerCase(),
          source: isOutSystemApp ? 'ehs_app' : 'okadoc_browser',
        }
      );
      console.log('[LOG] useMeeting err', message);
    },
    onMeetingStateChanged: data => {
      const { state } = data;
      switch (state) {
        case 'CONNECTING':
          console.log('Meeting is Connecting');
          show({
            type: 'success',
            message: t('Meeting is Connecting')
          });
          break;
        case 'CONNECTED':
          console.log('Meeting is Connected');
          show({
            type: 'success',
            message: t('Meeting is Connected')
          });
          break;
        case 'FAILED':
          console.log('Meeting connection failed');
          show({
            type: 'danger',
            message: t('Meeting connection failed')
          });
          break;
        case 'DISCONNECTED':
          console.log('Meeting connection disconnected abruptly');
          show({
            type: 'danger',
            message: t('Meeting connection disconnected abruptly')
          });
          break;
        case 'CLOSING':
          console.log('Meeting is closing');
          show({
            type: 'success',
            message: t('Meeting is closing')
          });
          break;
        case 'CLOSED':
          console.log('Meeting connection closed');
          show({
            type: 'success',
            message: t('Meeting connection closed')
          });
          break;
      }
    }
  });

  /* This is a hook that will be called `video_end` when the user closed the tab. */
  useEffect(() => {
    const handleLogUserLeave = () => {
      try {
        const remoteUser = !isDoctor ? doctor : patient;
        const userName = isDoctor ? remoteUser?.title : [remoteUser?.first_name, remoteUser?.last_name].join(' ');
        const format = !showFullChat ? 'video_end' : 'chat_end';
        const eventEndCall = {
          ...(!isDoctor ? { patient: userName } : { practitioner: userName }),
          format,
          message: format,
          text: format,
          time: getFullDatetime,
          sender: !isDoctor ? 'patient' : 'doctor'
        };
        const eventPayload = JSON.stringify(eventEndCall);

        action.saveChat({
          appointmentNumber: appointment.appointmentNumber,
          message: eventEndCall
        });
        publishChat(eventPayload, { persist: false });
        tracking.trackEvent('leave_room', isDoctor ? 'doctor' : 'patient', {
          micOn: useGlobalConfigStore.getState().micOn,
          webcamOn: useGlobalConfigStore.getState().webcamOn,
          action: 'close_tab'
        });
      } catch (err) {
        console.error('Error -> ', err);
      }
    };

    window.addEventListener('beforeunload', handleLogUserLeave);

    return () => {
      window.removeEventListener('beforeunload', handleLogUserLeave);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (meetingId && isJoined && shallRecord && !isRecording && !showFullChat) {
      startRecording(null, null, recordingConfig);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingId, isJoined, shallRecord, showFullChat]);

  /* This is a hook that will be called `video_join` when the user joined the meeting. */
  useEffect(() => {
    if (
      (isJoined && !_mounted.current) ||
      (isJoined && _mounted.current && !isDoctor && appointment?.isODDConsultation)
    ) {
      try {
        const currentUser = isDoctor ? doctor : patient;
        const userName = isDoctor ? currentUser?.title : [currentUser?.first_name, currentUser?.last_name].join(' ');

        const eventJoinCall = {
          ...(!isDoctor ? { patient: userName } : { practitioner: userName }),
          appointmentNumber: appointment?.appointmentNumber,
          format: showFullChat ? 'chat_start' : 'video_join',
          message: `${userName}`,
          text: `${userName}`,
          time: new Date().toISOString(),
          sender: !isDoctor ? 'patient' : 'doctor'
        };

        publishChat(JSON.stringify(eventJoinCall), { persist: false });
        action.saveChat({
          appointmentNumber: appointment?.appointmentNumber,
          message: eventJoinCall
        });

        _mounted.current = true;
      } catch (err) {
        console.error('Error -> ', err);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isJoined, isDoctor, showFullChat, showVideo, appointment, _mounted]);

  return (
    <MeetingContainer
      participantType={participantType}
      showVideo={showVideo}
      showChat={showChat}
      showInfo={showInfo}
      setShowVideo={setShowVideo}
      setShowChat={setShowChat}
      setShowInfo={setShowInfo}
      setShowFullChat={setShowFullChat}
      showFullChat={showFullChat}
      isPatient={isPatient}
      country={country}
      lang={lang}
      isXToken={isXToken}
      setIsUnread={setIsUnread}
      t={t}
      wlVersion={wlVersion}
    />
  );
};

export default Meeting;
