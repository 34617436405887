import React, { Fragment, lazy, Suspense, useMemo } from 'react';
import { Microphone, MicrophoneSlash, VideoCamera, VideoCameraSlash, SquaresFour, Phone, SpeakerHigh } from 'phosphor-react';
import styled from 'styled-components';
import { mobileBreakPoint } from 'libs/okaBrowserCheck';

const OverlayTrigger = lazy(() => import('react-bootstrap/OverlayTrigger'));
const Tooltip = lazy(() => import('react-bootstrap/Tooltip'));

const MessageTooltip = styled.div`
  text-align: center;
  background-color: white;
  padding: 8px;
  border-radius: 6px;
  z-index: 9999;
  min-width: 120px;
  max-width: 180px;
`;

const TooltipMessage = ({ id = '', message = '', children }) => (
  <Suspense fallback={''}>
    <OverlayTrigger
      key={id}
      placement='top'
      delay={{ show: 250, hide: 400 }}
      overlay={
        ((props) => (
          <Tooltip id={`tooltip-${id}`} {...props}>
            <MessageTooltip>
              {message}
            </MessageTooltip>
          </Tooltip>
        ))
      }
    >
      {children}
    </OverlayTrigger>
  </Suspense>
);

const ToolboxIcon = ({
  ariaLabel,
  active,
  iconActive,
  iconNonActive,
  toggleIcon,
  hasMultipleSources = false,
  onDeviceListClick,
  children,
  withTooltipMessage = '',
  ...props
}) => {
  const isMobile = mobileBreakPoint();
  const icons = {
    Microphone,
    MicrophoneSlash,
    VideoCamera,
    VideoCameraSlash,
    SquaresFour,
    Phone,
    SpeakerHigh,
  };

  const Icon = useMemo(
    () => {
      const iconName = active ? iconActive : iconNonActive;

      return icons?.[iconName];
    },
    [active, iconActive, iconNonActive]
  );

  const IconWrapper = useMemo(
    () => withTooltipMessage && !isMobile
      ? TooltipMessage
      : Fragment,
    [withTooltipMessage]
  );

  const wrapperProps = useMemo(
    () => {
      if (withTooltipMessage) return {
        message: withTooltipMessage
      };

      return { };
    },
    [withTooltipMessage]
  );

  return (
    <div className="toolbox-button-container">
      <IconWrapper {...wrapperProps}>
        <div aria-label={ariaLabel} className="toolbox-button" role="button" {...props}>
          <div className={`toolbox-icon ${toggleIcon ? 'toggled' : ''}`}>
            <Icon size={20} weight="bold" />
          </div>
          {hasMultipleSources && (
            <div onClick={onDeviceListClick} className="settings-button-small-icon" id="video-settings-button" role="button">
              <svg fill="none" height="9" width="9" viewBox="0 0 10 7"><path clipRule="evenodd" d="M8.411 6.057A.833.833 0 109.65 4.943L5.73.563a.833.833 0 00-1.24 0L.63 4.943a.833.833 0 001.24 1.114l3.24-3.691L8.41 6.057z"></path></svg>
            </div>
          )}
        </div>
      </IconWrapper>
      { children }
    </div>
  );
};

export default ToolboxIcon;
