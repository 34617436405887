import React, { memo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useGlobalConfigStore, useServerEventsStore } from 'zustandStore';
import useChat from 'libs/hooks/useChat';
import { EVENT_PUBSUB } from 'libs/constant';
import { usePubSub } from '@videosdk.live/react-sdk';
import { mobileBreakPoint } from 'libs/okaBrowserCheck';
import Button from 'components/Button';
import { useTimer } from 'libs/hooks/useTimer';
import { DRAWER_TYPE } from 'containers/video/chat-view/constants';

const CountdownTelemedicine = memo(
  ({ messagesConsultEnd, isEndSession }) => {
    const telemedicineStartTime = useServerEventsStore(state => state.telemedicineStartTime);
    const { hours, minutes, seconds, startTimer, stopTimer } = useTimer(telemedicineStartTime);

    useEffect(() => {
      const isConsultEnd = !!messagesConsultEnd.length && messagesConsultEnd[messagesConsultEnd.length - 1];
      if (isEndSession || !!isConsultEnd) {
        stopTimer();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEndSession, messagesConsultEnd]);

    useEffect(() => {
      if (!isEndSession && telemedicineStartTime) {
        startTimer();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [telemedicineStartTime]);

    return (
      <div className="chatbox-header-menu__info-text text-center">
        {`${hours}:${minutes}:${seconds}`}
      </div>
    );
  },
  (prev, next) => {
    if (prev.isEndSession === next.isEndSession || prev.messagesConsultEnd?.length === next?.messagesConsultEnd?.length) {
      return true;
    }

    return false;
  }
);

const Left = ({
  isODDConsultation,
  showVideo,
  showBackButton,
  isSessionEnd,
  showChat,
  setShowChat,
  showInfo,
  setShowInfo,
  setShowFullChat,
  handleClickVideo,
  setIsUnread,
  isUnread,
  handleSwitchCamera,
  isMeetingStarted,
  setShowAlert,
  setAlertType,
  t
}) => {
  const {
    action,
    value: { telemedicine, isDoctor }
  } = useChat();
  const isEndSession = useGlobalConfigStore(state => state.isEndSession);
  const shallRecord = telemedicine?.shallrecorded;
  const isMobile = mobileBreakPoint();
  const { messages: messagesConsultEnd } = usePubSub(EVENT_PUBSUB.CONSULTATION_END);
  const isPatientJoinTheCall = useGlobalConfigStore(state => state.isPatientJoinTheCall);

  const handleEndConsultation = useCallback(
    () => {
      if (typeof action?.endSession === 'function') action?.endSession();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shallRecord]
  );

  const handleClickChat = () => {
    setShowFullChat(false);
    setShowInfo(false);
    setShowChat(prevCheck => !prevCheck);
    setIsUnread(false);
  };

  const handleClickInfo = () => {
    setShowInfo(prevCheck => !prevCheck);
    setShowChat(false);
  };

  if (!isMeetingStarted) {
    return null;
  }

  return (
    <div className={classnames('chatbox-header', { hide: isMobile && (showInfo || showChat) })}>
      <div className="container">
        <div className="chatbox-header-menu">
          <div className="chatbox-header-menu__back-button">
            {showBackButton && (
              <Button
                variant="back"
                onClick={() => {
                  alert('clicked');
                }}
              />
            )}
            {!isSessionEnd && isDoctor && (
              <Button disabled={isSessionEnd} onClick={handleEndConsultation} variant="end" />
            )}
            {!isSessionEnd && !isDoctor && isMobile && (
              <div className="chatbox-header-menu__button-group justify-content-start">
                {isODDConsultation && !showVideo ? (
                  <Button
                    onClick={() => {
                      setShowAlert(true);
                      if (isMeetingStarted && isODDConsultation && isPatientJoinTheCall && !isDoctor) {
                        setAlertType(DRAWER_TYPE.CLOSE_APPOINTMENT);
                      } else {
                        setAlertType(DRAWER_TYPE.SCHEDULE_APPOINTMENT);
                      }
                    }}
                    variant="close"
                  ></Button>
                ) : (
                  <Button disabled={isSessionEnd} onClick={handleSwitchCamera} variant="switch" />
                )}
              </div>
            )}
          </div>
          <div className="chatbox-header-menu__info">
            {isMeetingStarted && isODDConsultation && !isPatientJoinTheCall && !isDoctor ? (
              <div className="chatbox-header-menu__info-text-chat text-center text-bold">{t('Chat')}</div>
            ) : (
              <CountdownTelemedicine messagesConsultEnd={messagesConsultEnd} isEndSession={isEndSession} />
            )}
          </div>
          {isSessionEnd || (!isDoctor && !isEndSession && isODDConsultation && !isPatientJoinTheCall) ? (
            <div className="chatbox-header-menu__button-group" />
          ) : (
            <div className="chatbox-header-menu__button-group">
              {showVideo ? (
                <Button
                  disabled={isSessionEnd}
                  onClick={handleClickChat}
                  variant="chat"
                  unread={isUnread}
                  active={showChat}
                />
              ) : (
                <Button disabled={isSessionEnd} onClick={handleClickVideo} variant="video" />
              )}
              <Button disabled={isSessionEnd} onClick={handleClickInfo} variant="info" active={showInfo} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Left.propTypes = {
  showVideo: PropTypes.bool,
  showBackButton: PropTypes.bool,
  showEndConsultation: PropTypes.bool,
  isSessionEnd: PropTypes.bool,
  showChat: PropTypes.bool,
  isNewChat: PropTypes.bool,
  showToggle: PropTypes.bool
};

Left.defaultProps = {
  showVideo: false,
  showBackButton: false,
  showEndConsultation: false,
  isSessionEnd: false,
  showChat: false,
  isNewChat: false,
  showToggle: false
};

export default Left;
