import { useCallback, useState } from "react";
import { useMeeting } from "@videosdk.live/react-sdk";
import { useTranslation } from 'react-i18next';
import useDeviceErrorMessageStore from "zustandStore/deviceErrorMessage";

export const MESSAGES_ERROR = {
  3012: "Audio not supported on this device",
  3015: "The permission to use microphone is rejected.",
  3018: "The permission to use microphone is rejected.",
  3020: "Please check the microphone permission for this app via device setting",
  3022: "Please check the microphone permission for this app via device setting",
  3034: "Please check the microphone permission for this app via device setting",
  3024: "Microphone is used by another application",
  3028: "Microphone suddenly disconnected. Please unmute to talk",
};

export const useVideoSDK = (props) => {
  const [isJoined, setIsJoined] = useState(false);
  const { t } = useTranslation();
  const setDeviceErrorMessage = useDeviceErrorMessageStore(state => state.setDeviceErrorMessage);

  const onMeetingJoined = useCallback(
    () => {
      if (typeof props?.onMeetingJoined === 'function') {
        props?.onMeetingJoined();
      }

      if (!isJoined) setIsJoined(true);
    },
    [isJoined]
  );

  const onMeetingLeft = useCallback(() => {
    if (typeof props?.onMeetingLeft === 'function') {
      props?.onMeetingLeft();
    }

    if (isJoined) setIsJoined(false);
  }, [isJoined, props?.onMeetingLeft]);

  const onErrorMeeting = useCallback((error) => {
    if (typeof props?.onError === 'function') {
      props?.onError(error);
    }

    const { code } = error;
    if (MESSAGES_ERROR?.[code]) {
      setDeviceErrorMessage(t(MESSAGES_ERROR?.[code]), 'audio');
    }
  }, [props?.onError]);

  const meetingSource = useMeeting({
    ...props,
    onMeetingJoined,
    onMeetingLeft,
    onError: onErrorMeeting
  });

  return {
    ...meetingSource,
    isJoined
  };
};
