import { create } from 'zustand';
import { CONSULTATION_STATUS } from 'libs/constant';

const useGlobalConfigStore = create(set => ({
  webcamOn: false,
  micOn: false,
  isEndSession: false,
  leavedScreenStates: null,
  webcamDeviceId: null,
  networkBarEnabled: true,
  micDeviceId: null,
  meetingStarted: false,
  isShowAppDrawerUpload: false,
  isShowExamModal: false,
  isExamFromAlert: false,
  selectedExamReport: {},
  isPatientJoinTheCall: false,
  newExamReportAlert: {},
  appToken: '',
  participantStatus: { practitioner: CONSULTATION_STATUS.YET_TO_JOIN, patient: CONSULTATION_STATUS.YET_TO_JOIN },
  isShowCSAT: false,
  showCSATDone: false,
  // Start Video and Audio Track
  appointmentNumber: '',
  audio_track: null,
  video_track: null,
  active_audio_track: '',
  active_video_track: '',

  setAppointmentNumber: appointmentNumber => set(() => ({ appointmentNumber })),
  setAudioTracks: audioLists => set(() => ({ audio_track: audioLists })),
  setVideoTracks: videoLists => set(() => ({ video_track: videoLists })),
  setCurrentAudio: audio => set(() => ({ active_audio_track: audio })),
  setCurrentVideo: video => set(() => ({ active_video_track: video })),
  // End Video and Audio Track

  // VideoAudioStatistic
  videoQualityStats: null,
  audioLevel: 0,
  videoSize: { width: 0, height: 0 },
  setVideoQualityStats: videoQualityStats => set(() => ({ videoQualityStats })),
  setAudioLevel: audioLevel => set(() => ({ audioLevel })),
  setVideoSize: videoSize => set(() => ({ videoSize })),
  // End VideoAudioStatistic

  toggleWebcamOn: () => set(state => ({ webcamOn: !state.webcamOn })),
  toggleMicOn: () => set(state => ({ micOn: !state.micOn })),

  setVideoEnabled: videoEnabled => set(() => ({ webcamOn: videoEnabled })),
  setMicEnabled: micEnabled => set(() => ({ micOn: micEnabled })),

  setIsEndSession: isEndSession => set(() => ({ isEndSession })),
  toggleState: updatedState => set(state => ({ ...state, ...updatedState })),
  setWebcamDeviceId: webcamDeviceId => set(() => ({ webcamDeviceId })),
  setMicDeviceId: micDeviceId => set(() => ({ micDeviceId })),
  setNetworkBarEnabled: networkBarEnabled => set(() => ({ networkBarEnabled })),
  setMeetingStarted: meetingStarted => set(() => ({ meetingStarted })),
  setIsShowAppDrawerUpload: value => set(() => ({ isShowAppDrawerUpload: value })),
  setIsExamFromAlert: value => set(() => ({ isExamFromAlert: value })),
  setIsShowExamModal: value => set(() => ({ isShowExamModal: value })),
  setNewExamReportAlert: value => set(() => ({ newExamReportAlert: value })),
  setSelectedExamReport: selectedExamReport => set({ selectedExamReport }),
  setAppToken: appToken => set({ appToken })
}));

export default useGlobalConfigStore;
