import { create } from 'zustand';

const InitialState = {
  errors: {
    audio: "",
    webcam: ""
  }
};

const useDeviceErrorMessageStore = create(set => ({
  InitialState,
  setDeviceErrorMessage: (message, device) => set(
    (state) => ({
      errors: {
        ...state.errors,
        [device]: message
      }
    })
  )
}));

export default useDeviceErrorMessageStore;
