import { useMemo } from 'react';

const Component = props => {
  const score = props?.score;
  const isLocal = props?.isLocal ?? false;

  const audioStats = useMemo(() => props?.audioStats ?? [], [props?.audioStats]);

  const videoStats = useMemo(() => props?.videoStats ?? [], [props?.videoStats]);

  const qualityStateArray = useMemo(
    () => [
      { label: '', audio: 'Audio', video: 'Video' },
      {
        label: 'Latency',
        audio: audioStats && audioStats[0]?.rtt ? `${audioStats[0]?.rtt} ms` : '-',
        video: videoStats && videoStats[0]?.rtt ? `${videoStats[0]?.rtt} ms` : '-'
      },
      {
        label: 'Jitter',
        audio: audioStats && audioStats[0]?.jitter ? `${parseFloat(audioStats[0]?.jitter).toFixed(2)} ms` : '-',
        video: videoStats && videoStats[0]?.jitter ? `${parseFloat(videoStats[0]?.jitter).toFixed(2)} ms` : '-'
      },
      {
        label: 'Packet Loss',
        audio: audioStats
          ? audioStats[0]?.packetsLost
            ? `${parseFloat((audioStats[0]?.packetsLost * 100) / audioStats[0]?.totalPackets).toFixed(2)}%`
            : '-'
          : '-',
        video: videoStats
          ? videoStats[0]?.packetsLost
            ? `${parseFloat((videoStats[0]?.packetsLost * 100) / videoStats[0]?.totalPackets).toFixed(2)}%`
            : '-'
          : '-'
      },
      {
        label: 'Bitrate',
        audio: audioStats && audioStats[0]?.bitrate ? `${parseFloat(audioStats[0]?.bitrate).toFixed(2)} kb/s` : '-',
        video: videoStats && videoStats[0]?.bitrate ? `${parseFloat(videoStats[0]?.bitrate).toFixed(2)} kb/s` : '-'
      },
      {
        label: 'Frame rate',
        audio: '-',
        video:
          videoStats && (videoStats[0]?.size?.framerate === null || videoStats[0]?.size?.framerate === 'undefined')
            ? '-'
            : `${videoStats ? videoStats[0]?.size?.framerate : '-'}`
      },
      {
        label: 'Resolution',
        audio: '-',
        video: videoStats
          ? videoStats && videoStats[0]?.size?.width === null
            ? '-'
            : `${videoStats[0]?.size?.width}x${videoStats[0]?.size?.height}`
          : '-'
      },
      {
        label: 'Codec',
        audio: audioStats && audioStats[0]?.codec ? audioStats[0]?.codec : '-',
        video: videoStats && videoStats[0]?.codec ? videoStats[0]?.codec : '-'
      },
      {
        label: 'Cur. Layers',
        audio: '-',
        video:
          videoStats && !isLocal
            ? videoStats && videoStats[0]?.currentSpatialLayer === null
              ? '-'
              : `S:${videoStats[0]?.currentSpatialLayer || 0} T:${videoStats[0]?.currentTemporalLayer || 0}`
            : '-'
      },
      {
        label: 'Pref. Layers',
        audio: '-',
        video:
          videoStats && !isLocal
            ? videoStats && videoStats[0]?.preferredSpatialLayer === null
              ? '-'
              : `S:${videoStats[0]?.preferredSpatialLayer || 0} T:${videoStats[0]?.preferredTemporalLayer || 0}`
            : '-'
      }
    ],
    [audioStats, isLocal, videoStats]
  );

  return (
    <div className="network-indicator-table">
      <div
        style={{
          padding: 9,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: score > 7 ? '#3BA55D' : score > 4 ? '#faa713' : '#FF5D5D'
        }}
      >
        <div style={{ fontWeight: 600 }}>
          {`Quality Score : ${score > 7 ? 'Good' : score > 4 ? 'Average' : 'Poor'}`}
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {qualityStateArray.map((item, index) => {
            return (
              <div
                key={`item-${index}`}
                style={{
                  display: 'flex',
                  borderBottom: index === qualityStateArray.length - 1 ? '' : '1px solid #ffffff33'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    width: 120,
                    alignItems: 'center'
                  }}
                >
                  {index !== 0 && (
                    <div
                      style={{
                        fontSize: 12,
                        marginTop: 6,
                        marginBottom: 6,
                        marginLeft: 8
                      }}
                    >
                      {item.label}
                    </div>
                  )}
                </div>
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderLeft: '1px solid #ffffff33'
                  }}
                >
                  <div
                    style={{
                      fontSize: 12,
                      marginTop: 6,
                      marginBottom: 6,
                      width: 65,
                      textAlign: 'center'
                    }}
                  >
                    {item.audio}
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderLeft: '1px solid #ffffff33'
                  }}
                >
                  <div
                    style={{
                      fontSize: 12,
                      marginTop: 6,
                      marginBottom: 6,
                      width: 65,
                      textAlign: 'center'
                    }}
                  >
                    {item.video}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Component;
