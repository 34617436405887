import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useGlobalConfigStore, useAudioPreviewConfigStore } from 'zustandStore';
import ToolBox from 'components/Toolbox';
import InfoView from '../video/partial/info-view';
import { PreMeetingGlobalStyle, VideoPreview } from './styles';
import useChat from 'libs/hooks/useChat';
import ConferenceAPI from 'libs/api/conference';
import _isEmpty from 'lodash.isempty';
import { useMediaDevice, createMicrophoneAudioTrack, Constants } from '@videosdk.live/react-sdk';
import { setStorage } from 'libs/storage';
import useFeatureFlagAPI, {
  DIGITAL_CARE_SETTINGS,
  FEATURE_FLAG_DISABLE_START_WITH_CHAT_ONLY
} from 'libs/hooks/useFeatureFlagAPI';
import { isMobile } from 'libs/okaBrowserCheck';
import useMediaStream from 'libs/hooks/useMediaStream';
import useToast from 'libs/hooks/useToast';
import useTracking from 'libs/hooks/useTracking';

import { VirtualBackgroundProcessor } from '@videosdk.live/videosdk-media-processor-web';
import { getVirtualBackgroundUrl, isUseVirtualBackground } from 'libs/virtual-background';
import NetworkStats from './networkstats';

const PreMeeting = ({
  onJoinRoom,
  setIsSessionEnd,
  setShowChat = () => {},
  setShowFullChat = () => {},
  setShowVideo = () => {},
  isTestingMode = false,
  videoTrack: videoSrcTrack = null,
  MicOn,
  WebCamOn,
  setMicOn,
  setWebCamOn,
  setCurrentCameraStream,
  setCurrentMicStream
}) => {
  const tracking = useTracking({ from: 'PreMeeting' });
  const trackingPermission = useTracking({ from: 'Permission' });

  const videoProcessor = useRef();
  const toggleState = useGlobalConfigStore(state => state.toggleState);
  const webcamDeviceId = useGlobalConfigStore(state => state.webcamDeviceId);
  const micDeviceId = useGlobalConfigStore(state => state.micDeviceId);
  const setWebcamDeviceId = useGlobalConfigStore(state => state.setWebcamDeviceId);
  const setMicDeviceId = useGlobalConfigStore(state => state.setMicDeviceId);
  const setMeetingStarted = useGlobalConfigStore(state => state.setMeetingStarted);
  const setAudioPreviewDeviceId = useAudioPreviewConfigStore(state => state.setAudioPreviewDeviceId);
  const { getCameras, getMicrophones, checkPermissions, requestPermission } = useMediaDevice({
    onDeviceChanged
  });

  let debounceTimeout;

  async function onDeviceChanged(devices) {
    if (debounceTimeout) clearTimeout(debounceTimeout);

    debounceTimeout = setTimeout(async () => {
      console.log('Updating List called ===>');
      const newMicList = await getMicrophones();
      const newWebcamList = await getCameras();

      console.log('New Camera List ===> ', newWebcamList);

      setMicLists(newMicList);
      setWebcamLists(newWebcamList);
    }, 300); // Adjust the delay (in milliseconds) as needed
  }

  const isDisabledStartWithChatOnly = useFeatureFlagAPI(
    FEATURE_FLAG_DISABLE_START_WITH_CHAT_ONLY,
    DIGITAL_CARE_SETTINGS
  );

  const [videoTrack, setVideoTrack] = useState(null);
  const videoPlayerRef = useRef();
  const audioTracksRef = useRef();
  const [webcamLists, setWebcamLists] = useState([]);
  const [micLists, setMicLists] = useState([]);
  const [toggleShowDeviceList, setToggleShowDeviceList] = useState(false);
  const [toggleShowMicDeviceList, setToggleShowMicDeviceList] = useState(false);

  const setAudioTracks = useGlobalConfigStore(state => state.setAudioTracks);
  const setVideoTracks = useGlobalConfigStore(state => state.setVideoTracks);

  const { show } = useToast();
  const { getVideoTrack } = useMediaStream();

  const getPermission = async () => {
    try {
      const mediaPermission = await checkPermissions(Constants.permission.AUDIO_AND_VIDEO);
      const hasAccessAudio = mediaPermission.get(Constants.permission.AUDIO);
      const hasAccessVideo = mediaPermission.get(Constants.permission.VIDEO);

      let targetRequest = ''; // access permissions
      if (!hasAccessAudio && !hasAccessVideo) {
        targetRequest = Constants.permission.AUDIO_AND_VIDEO;
      }

      if (hasAccessVideo && !hasAccessAudio) {
        targetRequest = Constants.permission.AUDIO;
      } else if (hasAccessAudio && !hasAccessVideo) {
        targetRequest = Constants.permission.VIDEO;
      }

      if (targetRequest) {
        await trackingPermission.trackEvent('request_permission', isDoctor ? 'doctor' : 'patient', {
          request_permission_from: 'telemedicine-web',
          video_permission: targetRequest.includes('video'),
          audio_permission: targetRequest.includes('audio'),
          is_requesting: true
        });

        await requestPermission(targetRequest);
      }
    } catch (err) {
      console.error('Error request access: ', err);
    }
  };
  const setupDevicesAndPermissions = async () => {
    await getPermission();
    getMics();
    getDevices();
  };

  useEffect(() => {
    setupDevicesAndPermissions();
    videoProcessor.current = new VirtualBackgroundProcessor();
  }, []);

  useEffect(() => {
    if (!MicOn) {
      audioTracksRef?.current?.forEach(track => {
        track.stop();
      });
    }
    setStorage('MicOn', MicOn);
    setStorage('WebCamOn', WebCamOn);
  }, [WebCamOn, MicOn]);

  const { t } = useTranslation();
  const {
    value: { appointment, doctor, isDoctor },
    action
  } = useChat();
  const isODDConsultation = appointment?.isODDConsultation ?? false;

  const handleToggleMic = () => {
    setMicOn(state => !state);
    tracking.trackEvent(!MicOn ? 'enable_audio' : 'disable_audio', isDoctor ? 'doctor' : 'patient', {
      micOn: !MicOn,
      webcamOn: WebCamOn,
      action: 'pre_meeting_toolbox'
    });
    // setStorage('MicOn', MicOn);
    // toggleMicOn();
  };

  const handleClickOutside = () => {
    setToggleShowDeviceList(show => false);
    setToggleShowMicDeviceList(show => false);
  };

  const handleToggleLocalWebcam = async () => {
    if (!videoTrack) {
      getVideo();
    } else {
      if (videoTrack) {
        if (WebCamOn) {
          videoProcessor.current.stop();
          videoTrack.stop();
          videoPlayerRef.current.srcObject = null; // remove current srcObject video
        } else getVideo();

        setWebCamOn(state => !state);
        tracking.trackEvent(!WebCamOn ? 'enable_video' : 'disable_video', isDoctor ? 'doctor' : 'patient', {
          micOn: MicOn,
          webcamOn: !WebCamOn,
          action: 'pre_meeting_toolbox'
        });
        // setStorage('WebCamOn', WebCamOn);

        // toggleWebcamOn();
      }
    }
  };

  const hasJoinedMultipleDevice = async () => {
    const payload = { appointmentNumber: appointment?.appointmentNumber };
    // eslint-disable-next-line new-cap
    const [err, response] = await ConferenceAPI.GetActiveParticipant(payload);

    // find participant match with current user
    const hasJoined = response?.data?.data?.find(({ user_id: userId = '' }) =>
      userId.includes(isDoctor ? 'doctor' : 'patient')
    );

    return { hasJoined, err };
  };

  const handleJoinRoom = async e => {
    e.preventDefault();
    // await videoProcessor.current.stop();

    if ((isDoctor && isODDConsultation) || !isODDConsultation) {
      const { hasJoined, err } = await hasJoinedMultipleDevice();
      // Show toast error when failed to get participant active
      if (hasJoined || err) {
        show({
          type: 'danger',
          message: err
            ? err?.response
            : t(
                'You are already logged in from one device, please close that session before joining from a second device.'
              )
        });

        return;
      }
    }

    setShowVideo(true);
    if (!isDoctor && isODDConsultation) {
      setShowChat(false);
      setShowFullChat(false);

      toggleState({ isPatientJoinTheCall: true });

      action?.setIsShowAppDrawer(false);
    }
    const data = {
      appointmentNumber: appointment.appointmentNumber,
      message: {
        format: 'video_start',
        text: `${appointment.participantName}`,
        message: `${appointment.participantName}`,
        time: new Date().toISOString()
      }
    };
    action.saveChat(data);
    setMeetingStarted(true);

    onJoinRoom();
    // setIsSessionEnd(false);
  };

  const handleJoinChat = async e => {
    e.preventDefault();
    const { hasJoined, err } = await hasJoinedMultipleDevice();
    // Show toast error when failed to get participant active
    if (hasJoined || err) {
      show({
        type: 'danger',
        message: err
          ? err?.response
          : t(
              'You are already logged in from one device, please close that session before joining from a second device.'
            )
      });

      return;
    }

    setShowVideo(false);

    // toggle mic and webcam off
    toggleState({
      webcamOn: false,
      micOn: false,
      leavedScreenStates: { webcamOn: WebCamOn, micOn: MicOn }
    });

    if (videoTrack) {
      videoTrack.stop();
      setVideoTrack(null);
    }

    setStorage('MicOn', false);
    setStorage('WebCamOn', false);

    setMeetingStarted(true);
    onJoinRoom();
    setIsSessionEnd(false);

    setTimeout(() => {
      setShowFullChat(true);
    }, 100);
  };

  const processVideoStream = async stream => {
    try {
      if (isUseVirtualBackground(isDoctor) && !videoProcessor.current.ready) {
        await videoProcessor.current.init();
      }

      // default background from window.location
      if (isUseVirtualBackground(isDoctor)) {
        const config = {
          type: 'image',
          imageUrl: getVirtualBackgroundUrl()
        };
        const processedStream = await videoProcessor.current.start(stream, config);
        return processedStream;
      } else {
        return stream;
      }
    } catch (err) {
      throw new Error('Failed to process video stream');
    }
  };

  const getVideo = async (deviceId = '') => {
    if (videoPlayerRef.current) {
      if (videoTrack) {
        videoTrack.stop();
        setVideoTrack(null);
        videoPlayerRef.current.srcObject = null;
      }
      try {
        const cameraConfig = {
          optimizationMode: 'motion',
          cameraId: deviceId ?? webcamDeviceId,
          encoderConfig: isDoctor ? 'h540p_w720p' : 'h720p_w1280p',
          multiStream: isDoctor ? true : false,
          facingMode: isMobile ? 'user' : 'environment'
        };

        const cameraStream = await getVideoTrack(cameraConfig);

        const videoTracks = cameraStream?.getVideoTracks();

        const videoTrack = videoTracks?.length ? videoTracks[0] : null;

        // const getWebcamDeviceId = track.getSettings().deviceId;
        const videoStream = new MediaStream([videoTrack]);
        const processedStream = await processVideoStream(videoStream);

        videoPlayerRef.current.srcObject = processedStream;
        videoPlayerRef.current.play();
        setCurrentCameraStream(processedStream);
        setVideoTrack(videoTrack);
      } catch (err) {
        const errorName = err?.name ?? '';

        if (errorName === 'NotReadableError' || errorName === 'TrackStartError') {
          show({
            type: 'danger',
            message: t('Could not start video source. The camera might be in use by another application.')
          });
        }

        toggleState({ webcamOn: false });
        console.error(`err: ${err}`);
      }
    }
  };

  const setMicStream = async (deviceId = '') => {
    try {
      const micStream = await createMicrophoneAudioTrack({
        microphoneId: deviceId ?? micDeviceId
      });

      setCurrentMicStream(micStream);

      const audioTracks = micStream?.getAudioTracks();
      audioTracksRef.current = audioTracks;

      if (micDeviceId) {
        if (MicOn) toggleState({ micOn: false });
      }
    } catch (e) {
      console.log('Error initially in mic', e);
    }
  };

  const getDevices = async () => {
    try {
      const webcamsVideoSDK = await getCameras();
      const getWebcams = webcamsVideoSDK.filter(d => d.kind === 'videoinput');

      if (getWebcams) {
        const webcams =
          getWebcams?.map((webcam, index) => {
            const label = _isEmpty(webcam.label) ? `Video ${index + 1}` : webcam.label;

            return { deviceId: webcam.deviceId, label: label };
          }) ?? [];

        setVideoTracks(webcams);
        setWebcamLists(webcams);
        setWebcamDeviceId(webcams[0]?.deviceId);
        getVideo(webcams[0]?.deviceId);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getMics = async () => {
    try {
      const devices = await getMicrophones();

      const getMics = devices.filter(d => d.kind === 'audioinput') ?? [];

      if (getMics?.length) {
        const mics = getMics?.map((mic, index) => {
          const label = _isEmpty(mic.label) ? `Audio ${index + 1}` : mic.label;

          return { deviceId: mic.deviceId, label: label };
        });
        setAudioTracks(mics);
        setMicLists(mics);
        setMicStream(mics[0]?.deviceId);
        setMicDeviceId(mics[0]?.deviceId);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleShowDeviceList = e => {
    e.stopPropagation();
    setToggleShowDeviceList(show => !show);
    setToggleShowMicDeviceList(show => false);
  };

  const handleShowMicDeviceList = e => {
    e.stopPropagation();
    setToggleShowDeviceList(show => false);
    setToggleShowMicDeviceList(show => !show);
  };

  const handleChangeCamera = useCallback(
    cameraId => {
      if (cameraId) {
        videoProcessor?.current.stop();
        getVideo(cameraId);
        setWebcamDeviceId(cameraId);
        setToggleShowDeviceList(false);
        setToggleShowMicDeviceList(false);
        // toggleState({ webcamOn: true });
      }
    },
    []
  );

  const handleChangeMic = useCallback(
    micId => {
      if (micId) {
        setMicDeviceId(micId);
        setMicStream(micId);
        setToggleShowDeviceList(false);
        setToggleShowMicDeviceList(false);
        setAudioPreviewDeviceId(micId);
        // toggleState({ micOn: true });
      }
    },
    []
  );

  useEffect(() => {
    const defaultMicLabel = micLists.find(mic => mic.deviceId === 'default')?.label;
    if (defaultMicLabel) {
      const micsByLabel = micLists.filter(mic => defaultMicLabel.includes(mic.label) && mic.deviceId !== 'default');
      setAudioPreviewDeviceId(micsByLabel?.[0]?.deviceId || 'default');
    } else {
      setAudioPreviewDeviceId(micDeviceId);
    }
  }, [micLists]);

  const showGeneralAction = (isODDConsultation && isDoctor) || !isODDConsultation;

  return (
    <>
      <PreMeetingGlobalStyle isDoctor={isDoctor} isODDConsultation={isODDConsultation} />
      <div className="video-embed active">
        <div id="lobby-screen" className="premeeting-screen">
          <div className="premeeting-screen-header-container">
            <div id="header-title-container" className="content">
              <h2 className="title">
                {isODDConsultation && !isDoctor
                  ? t('Ready to start the video call?')
                  : t('This consultation is ready to start')}
              </h2>
            </div>
            {isODDConsultation && !isDoctor && (
              <div className="title-extra">{t('{{doctor}} is already in the call', { doctor: doctor?.fullName })}</div>
            )}
          </div>

          <div className="premeeting-screen-preview-container">
            <VideoPreview id="preview" className="content">
              <video autoPlay playsInline muted ref={videoPlayerRef} controls={false} className="video-pre-meeting" />
              <div className="preview-caption-text">{t('Video Preview')}</div>
              <NetworkStats />
            </VideoPreview>

            <div id="preview-navigation" className="content">
              <div className="prejoin-button-container">
                <button className="button green action-btn" onClick={handleJoinRoom}>
                  {showGeneralAction && (
                    <img
                      src="https://img.okadoc.com/photos/block_images/img/icon/videocam.svg"
                      className="icon-button"
                      alt="preview video"
                    />
                  )}
                  {isODDConsultation && !isDoctor ? t('Start Call') : t('Start with video')}
                </button>
              </div>
              {!isDisabledStartWithChatOnly && showGeneralAction && (
                <div className="prejoin-chat-button">
                  <p className="prejoin-chat-button__text">
                    {t('Or you can')}
                    <span className="prejoin-chat-button__action" onClick={handleJoinChat}>
                      <img
                        src="https://img.okadoc.com/photos/block_images/img/icon/ic-chatbox.svg"
                        alt="chatbox icon"
                      />
                      {t('Start with chat only')}
                    </span>
                  </p>
                </div>
              )}
              <ToolBox
                micOn={MicOn}
                webcamOn={WebCamOn}
                handleToggleMic={handleToggleMic}
                handleToggleWebcam={handleToggleLocalWebcam}
                showSwitchView={false}
                showEndCall={false}
                handleShowDeviceList={handleShowDeviceList}
                handleChangeCamera={handleChangeCamera}
                showDeviceList={toggleShowDeviceList}
                deviceLists={webcamLists}
                hasMultipleSources
                showAudioPreview
                micLists={micLists}
                handleShowMicDeviceList={handleShowMicDeviceList}
                showMicList={toggleShowMicDeviceList}
                handleChangeMic={handleChangeMic}
                handleClickOutside={handleClickOutside}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={classnames('sidebar', 'chat--sidebar', { hide: true })}>
        <InfoView t={t} isTestingMode={isTestingMode} />
      </div>
    </>
  );
};

PreMeeting.propTypes = {
  onJoinRoom: PropTypes.func,
  setShowFullChat: PropTypes.func,
  setIsSessionEnd: PropTypes.func,
  setShowVideo: PropTypes.func,
  setShowChat: PropTypes.func,
  isTestingMode: PropTypes.bool,
  showInfo: PropTypes.bool,
  micOn: PropTypes.bool,
  webcamOn: PropTypes.bool
};

export default PreMeeting;
