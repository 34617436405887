const isUseVirtualBackground = (isDoctor) => {
  let isVirtualBackgroundEnabledOnEnv = true;
  if (process.env.REACT_APP_USE_VIRTUAL_BACKGROUND) {
    isVirtualBackgroundEnabledOnEnv = process.env.REACT_APP_USE_VIRTUAL_BACKGROUND === 'true' || process.env.REACT_APP_USE_VIRTUAL_BACKGROUND === '1';
  }
  const isDoctorUseVirtualBackground = process.env.REACT_APP_VIRTUAL_BACKGROUND_USER === 'doctor' && isDoctor;
  const isPatientUseVirtualBackground = process.env.REACT_APP_VIRTUAL_BACKGROUND_USER === 'patient' && !isDoctor;
  const isVirtualBackgroundEnabled = isVirtualBackgroundEnabledOnEnv && (isDoctorUseVirtualBackground || isPatientUseVirtualBackground);

  return isVirtualBackgroundEnabled;
};

const getVirtualBackgroundUrl = (isDoctor) => {
  const defaultBackground = [window.location.origin, 'default-background.png'].join('/');
  const selectedBackground = process.env.REACT_APP_VIRTUAL_BACKGROUND ?? '';

  return selectedBackground || defaultBackground;
};

export {
  isUseVirtualBackground,
  getVirtualBackgroundUrl,
};
