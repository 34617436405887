import { useState, useEffect } from 'react';
import { useGlobalConfigStore } from 'zustandStore';
import isAvailable, { FEATURE_HIGO } from 'libs/feature-flags';
import { EXAM_SSE_EVENTS } from 'libs/constant';
import { useVideoSDK } from 'libs/hooks/useVideoSDK';

const useAction = ({ isPatient, videoCallActive, chatActive, hasExamDevice, appointment }) => {
  const setNewExamReportAlert = useGlobalConfigStore(state => state.setNewExamReportAlert);
  const [sideBarRef, setSideBarRef] = useState(null);
  const [sideBarVideoRef, setSideBarVideoRef] = useState(null);
  const isShowAppDrawerUpload = useGlobalConfigStore(state => state.isShowAppDrawerUpload);
  const isEndSession = useGlobalConfigStore(state => state.isEndSession);
  const setIsShowAppDrawerUpload = useGlobalConfigStore(state => state.setIsShowAppDrawerUpload);
  const isShowExamModal = useGlobalConfigStore(state => state.isShowExamModal);
  const setIsShowExamModal = useGlobalConfigStore(state => state.setIsShowExamModal);

  const { isJoined } = useVideoSDK();

  useEffect(() => {
    if (!isPatient && videoCallActive && !chatActive) {
      setIsShowAppDrawerUpload(false);
    }
  }, [isPatient, videoCallActive, chatActive]);

  useEffect(() => {
    if (isAvailable([FEATURE_HIGO]) && isPatient && hasExamDevice) {
      // listen for new patient exam report
      listenForNewExam();
    }
  }, [appointment?.appointmentNumber]);

  const listenForNewExam = () => {
    const EXAM_SSE_URL = `${process.env.REACT_APP_PIGEON_API_URL}/event/stream/${EXAM_SSE_EVENTS.PATIENT_EXAM}`;
    const eventSource = new EventSource(`${EXAM_SSE_URL}-${appointment?.appointmentNumber}`.toLowerCase());
    eventSource.onmessage = eventStream => {
      const data = eventStream?.data;
      try {
        if (data) {
          setNewExamReportAlert(JSON.parse(data || '{}'));
        }
      } catch (error) {
        setNewExamReportAlert({});
      }
    };
  };

  const showLoading = () => {
    if (isEndSession) {
      return false;
    }

    return !isJoined && !isEndSession;
  };

  return {
    sideBarRef,
    setSideBarRef,
    sideBarVideoRef,
    setSideBarVideoRef,
    isShowAppDrawerUpload,
    setIsShowAppDrawerUpload,
    isShowExamModal,
    setIsShowExamModal,
    showLoading
  };
};

export default useAction;
