import { useEffect, useRef, useState } from 'react';
import { getNetworkStats } from '@videosdk.live/react-sdk';
import { Container, Download, Upload, Button } from './networkstats.styles';
import { ArrowDown, ArrowsClockwise, ArrowUp } from 'phosphor-react';

const NetworkStats = () => {
  const intervalRef = useRef(null);
  const [networkStats, setNetworkStats] = useState({ downloadSpeed: 0, uploadSpeed: 0 });

  const fetchNetworkStats = async () => {
    try {
      const options = { timeoutDuration: 45000 }; // Custom timeout of 45 seconds
      const stats = await getNetworkStats(options);
      setNetworkStats(stats);
    } catch (error) {
      console.error('Error fetching network stats:', error);
    }
  };

  useEffect(() => {
    fetchNetworkStats();
    intervalRef.current = setInterval(() => {
      fetchNetworkStats();
    }, 5000); // Fetch network stats every 5 seconds

    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  return (
    <div
      style={{
        position: 'absolute',
        top: '0',
        right: '20px',
        margin: '20px auto', // Centers the table horizontally with auto margins
        backgroundColor: 'rgba(51, 51, 51,.5)',
        color: '#ffffff',
        padding: '10px',
        borderRadius: '4px',
        width: '180px', // Adjust this value to change the table's width
        textAlign: 'center' // Centers the text within the table
      }}
    >
      <Container>
        <Download>
          <ArrowDown />
          <div>{networkStats.downloadSpeed} Mbps</div>
        </Download>
        <Upload>
          <ArrowUp />
          <div>{networkStats.downloadSpeed} Mbps</div>
        </Upload>
        <Button>
          <ArrowsClockwise onClick={fetchNetworkStats} />
        </Button>
      </Container>
    </div>
  );
};

export default NetworkStats;
