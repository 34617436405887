/* eslint-disable valid-jsdoc */
import _isEmpty from 'lodash.isempty';
import store from 'store2';

const storageType = {
  sessionStorage: 'session',
  localStorage: 'local'
};

/**
 *
 * @param {*} isLocal flag storage whether session or local storage.
 * @return string of storage type.
 */
const checkStorage = (isLocal = false) => {
  return isLocal ? storageType.localStorage : storageType.sessionStorage;
};

/**
 *
 * @param {} name storage name
 * @param {*} data data who wanna stored
 * @param {*} isLocal flag storage whether session or local storage
 */
export const setStorage = (name, data, isLocal = false) => {
  try {
    store[checkStorage(isLocal)].set(name, data);
  } catch (e) {
    console.log(e);
  }
};

/**
 *
 * @param {*} storageName name of storage.
 * @return storage data, it will return false when have no data.
 */
export const getStorage = (storageName = '') => {
  try {
    const sessionData =
      store[storageType.localStorage].get(storageName) || store[storageType.sessionStorage].get(storageName);
    return sessionData || false;
  } catch (e) {
    return false;
  }
};

/**
 *
 * @param {*} storageName name of storage.
 */
export const destroyStorage = (storageName = '') => {
  /**
   * in case user logout session should be removed from session storage & local storage
   * */
  if (!_isEmpty(storageName)) {
    try {
      store[storageType.sessionStorage].remove(storageName);
      store[storageType.localStorage].remove(storageName);
    } catch (e) {
      console.log(e);
    }
  }
};

/**
 * get saved previous url
 * @return {string} url
 */
export const getPrevUrlInfo = () => {
  const defInfo = '';
  return store.get(process.env.PREV_URL_INFO_KEY, defInfo);
};

/**
 * remove previous url
 */
export const removePrevUrlInfo = () => {
  store.remove(process.env.PREV_URL_INFO_KEY);
};

/**
 *
 * @param {string} url path that wanna save
 */
export const savePrevUrlInfo = (url = '') => {
  try {
    if (!url && typeof window !== 'undefined') {
      url = window.location.pathname;
      const pathParam = window.location.search || false;

      if (url && pathParam) {
        url = `${url}${pathParam}`.replace(/&x-token=([a-zA-Z\d{.}-])+/g, '');
      }
    }

    if (typeof url === 'string' && !_isEmpty(url)) {
      store.set(process.env.PREV_URL_INFO_KEY, url);
    }
  } catch (error) {
    console.log('Unable to store previous login info: ', error);
  }
};

const storage = {
  setStorage,
  getStorage,
  destroyStorage
};

export default storage;
