import uniq from 'libs/uniq';

export const sendMessage = props => {
  let { message, domain, isWebview } = props;
  const sent = [];
  domain = domain ? `${window.location.protocol}//${domain}` : '*';

  if (typeof message === 'object') {
    message = JSON.stringify(message);
  }

  if (typeof window !== 'undefined' && typeof window.parent !== 'undefined') {
    sent.push('iframe');
    window.parent.postMessage(message, domain);
  }

  if (isWebview && typeof window !== 'undefined') {
    const target = window?.cordova_iab ?? window?.webkit?.messageHandlers?.cordova_iab;
    sent.push('webview');
    target?.postMessage(message);
  }
};

export const formatMessages = messages => {
  try {
    const result = messages
      .map(message => {
        if (message.appointment_number) {
          return {
            ...message,
            text: message.text.split('::::').length > 1 ? message.text.split('::::')[0] : message.text
          };
        }
        const parsedMessage = typeof message.message === 'string' ? JSON.parse(message.message) : message;
        return {
          id: message.id,
          format: parsedMessage.format,
          sender: parsedMessage.sender,
          text: parsedMessage.message || parsedMessage.text,
          filetype: parsedMessage.filetype,
          time: parsedMessage.time,
          created_date: parsedMessage.time,
          chatHistoryId: parsedMessage?.chatHistoryId,
          meta: parsedMessage?.meta
        };
      })
      .filter(m => m.text);
    const getUniqResultById = uniq(result, 'id');
    return getUniqResultById;
  } catch (error) {
    throw new Error(error);
  }
};
