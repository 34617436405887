import useService from "./useService";

export const DIGITAL_CARE_SETTINGS = 'digital_care_setting';

export const FEATURE_FLAG_DISABLED_BACK_BUTTON = 'disabled_back_button_telemedicine';
export const FEATURE_FLAG_DISABLE_START_WITH_CHAT_ONLY = 'disabled_start_with_chat_only';

const useFeatureFlagAPI = (flag = '', settingsName = '') => {
  const initContext = useService();

  if (!flag) return false;

  const config = initContext?.value?.init?.config;

  if (settingsName) {
    return !!config?.[settingsName]?.[flag];
  }

  return !!config?.[flag];
};

export default useFeatureFlagAPI;
