import React, { useState } from 'react';
import Svg from '../components/Svg';
import useRTL from '../libs/hooks/useRtl';

const Collapsible = props => {
  const [open, setOpen] = useState(false);
  const { dir } = useRTL();

  const onToggle = () => setOpen(prev => !prev);

  return (
    <div dir={dir} className="list-group__item" onClick={onToggle}>
      <div className="d-flex justify-content-between align-items-center">
        <span className="list-group__title">{props?.title}</span>
        <Svg
          external
          name="https://img.okadoc.com/photos/block_images/img/icon/ic-chevron-down.svg"
          alt="navigate"
          width="12px"
          style={{ transform: open ? '' : 'rotate(-90deg)' }}
        />
      </div>
      {open ? <div className="mt-15">{props?.children}</div> : null}
    </div>
  );
};

export default Collapsible;
