import useChat from './useChat';

export const RTL = 'rtl';
export const LTR = 'ltr';

const useRTL = () => {
  const { value } = useChat();
  const isArabic = value?.lang === 'ar';

  return {
    dir: isArabic ? RTL : LTR
  };
};

export default useRTL;
