const doctorCameraConfig = {
    optimizationMode: 'motion',
    encoderConfig: 'h540p_w720p',
    multiStream: true,
  };

  const patientCameraConfig = {
    optimizationMode: 'motion',
    encoderConfig: 'h720p_w1280p',
    multiStream: false,
  };

export {
    doctorCameraConfig,
    patientCameraConfig
};
