import styled from 'styled-components';
const Container = styled.div`
  display: flex;
  font-size: 0.75rem;
  align-items: center;
`;

const Download = styled.div`
  flex: 1 1 50%;
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const Upload = styled.div`
  flex: 1 1 50%;
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const Button = styled.div`
  background-color: transparent;
  border: none;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export { Container, Download, Upload, Button };
